import styled, { css } from 'styled-components'
import { TransitionStatus } from 'react-transition-group'

import { getThemeColor, getThemeTransition } from '@utils/styled';
import { media } from '@utils/mediaQueries'

import Modal from '@common/components/Modal';
import { ContentWrapper, StyledCloseButton } from '@common/components/Modal/Modal.styled';
import { Cross } from '@common/components/Icons';
import Typography from '@common/components/Typography';

export const ModalStyled = styled(Modal)`
  ${ContentWrapper} {
    ${media.from480down(
      css`
        padding: 0;
      `
    )}
  }

  ${StyledCloseButton} {
    opacity: 0 !important;
  }
`;

export const CrossIcon = styled(Cross)`
  fill: ${getThemeColor('bluegrey.15')};
  display: block;
`;

export const CloseButton = styled.button`
  border: none;
  background: none;
  cursor: pointer;
`;

export const OverlayContainer = styled.div`
  overflow: hidden;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export const OverlayContentWrapper = styled.section<{ $transitionState: TransitionStatus }>`
  position: relative;
  top: ${({ $transitionState }) => ($transitionState === 'entered' ? '0%' : '100%')};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  transition: ${getThemeTransition('top', 'slow')};

  ${media.from480up(
    css`
      ${({ theme }) => theme.getFluidSpacing('padding-top', 'scale', 3)}
      ${({ theme }) => theme.getFluidSpacing('padding-bottom', 'scale', 3)}
    `
  )}
`;

export const OverlayContent = styled.div<{ $contentWidth?: string }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  ${({ theme }) => theme.getFluidSpacing('padding-right', 'scale', 4)}
  ${({ theme }) => theme.getFluidSpacing('padding-left', 'scale', 4)}
  background-color: ${getThemeColor('grey.100')};

  ${({ $contentWidth }) => media.from480up(css`
    height: auto;
    max-height: 100%;
    max-width: ${$contentWidth};
    border-radius: ${({ theme }) => theme.borderRadiuses['normal.1']}rem;
  `)}
`;

export const OverlayTitle = styled(Typography).attrs({ variant: 'paragraph1', maxLines: 1 })`
  display: inline-block;
  margin: 0;
  margin-right: 1.5rem;
  font-weight: 500;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const OverlayTitleWrapper = styled.div<{ $titleWithBorder: boolean }>`
  display: flex;
  flex: 1 0 auto;
  justify-content: space-between;
  align-items: center;
  height: 6rem;
  ${({ $titleWithBorder }) =>
    $titleWithBorder &&
    css`
      border-bottom: 0.1rem solid ${getThemeColor('grey.80')};
    `};
`;
