import { css, RuleSet } from 'styled-components'

export const breakpoints = [
  1760,
  1680,
  1600,
  1520,
  1440,
  1360,
  1280,
  1200,
  1120,
  1040,
  960,
  880,
  800,
  720,
  640,
  560,
  480,
  400,
  320,
] as const;

type Breakpoints = typeof breakpoints[number];

type BreakpointsQuery = {
  [key in `from${Breakpoints}up` | `from${Breakpoints}down`]: string;
};

export const breakpointQuery = breakpoints
  .reduce<BreakpointsQuery>((accumulator, breakpoint: Breakpoints) => {
  accumulator[`from${breakpoint}up`] = `(min-width: ${breakpoint / 16}em)`;
  accumulator[`from${breakpoint}down`] = `(max-width: ${breakpoint / 16 - 0.02}em)`;

  return accumulator;
}, {} as BreakpointsQuery);

type MediaType = {
  [key in keyof BreakpointsQuery]: (cssArgs: RuleSet<object>) => ReturnType<typeof css>;
}

export const media: MediaType = (Object.keys(breakpointQuery) as Array<keyof BreakpointsQuery>)
  .reduce<MediaType>((accumulator, key) => {
    accumulator[key] = (cssArgs: RuleSet<object>) => css`
      @media ${breakpointQuery[key]} {
        ${cssArgs};
      }
    `;

  return accumulator;
}, {} as MediaType);
